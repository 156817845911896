html {
    min-height: 100%;
}

body.logged-out {
    display: flex;
    height: 100vh;
    flex-direction: column;

    @media (min-width: 768px) {
        background-color: #d3d3d3;
        background-image: url("https://assets.simon.com/content/simoncentral/welcome-sc.jpg");
        background-size: cover;
    }

    header .container-fluid

{
    display: none;
}

.loader-1 {
    display: none;
}

.logo-loader.loader-2 {
    display: block !important;
    margin-top: auto;
    height: 275px;
    .line

{
    @media (min-width: 768px) {
        background-color: #d3d3d3;
    }
}

}

#mainHeader {
    @media (max-width: 767.98px) {
        background-color: #ffffff;
    }

    @media (min-width: 768px) {
        background-color: #d3d3d3;
    }
}

footer {
    margin-top: auto !important;
}

.main-hero {
    display: none;
}
}
