.navbar-brand .img-logo {
    width: 300px;

    @media (--lg-viewport-down) {
        width: 220px;
    }
}

#mobileMenu {
    position: fixed;
    top: 74px;
    right: 0;
    margin: 0;
    min-width: 85%;
    background-color: #000;

    -webkit-overflow-scrolling: touch;
    z-index: 99;
    color: var(--white);

    &::before {
        content: '';
        background: rgba(0,0,0,0.6);
        position: fixed;
        top: 74px;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: -1;
        opacity: 0;
        transition: opacity 0.2s;
    }
    &.show::before,
    &.collapsing::before {
        opacity: 1;
    }

    & .mobile-menu-wrapper {
        height: calc(100vh - 74px);
        overflow-y: scroll;
        position: relative;
    }

    & .dropdown-menu {
        background-color: transparent;
        border: none;
        
        & .dropdown-item:hover,
        & .dropdown-item:focus,
        & .dropdown-item:active {
            background-color: var(--gray);
        }
    }
}

#mobileMenu ul {
    list-style: none;
    z-index:99;
}

.mobile-primary {
    background-color: #393939;
    margin-bottom: 0;
}

.mobile-secondary {
    background-color: #000;
}

#mobileMenu li {
    padding: 1rem 0;

    &:not(:first-child) {
      border-top: solid 1px;
      border-top-color: #757575;
    }
}

#mobileMenu a,
#mobileMenu .dropdown-menu,
#mobileMenu .dropdown-header {
    color: #fff;
    text-decoration: none;
}

#mobileMenu .dropdown-divider {
    border-top-color: #757575;
}

.navbar-fixed-top .dropdown-toggle:hover + .dropdown-menu,
.navbar-fixed-top .dropdown-menu:hover,
.navbar-expand-lg .dropdown-toggle:hover + .dropdown-menu,
.navbar-expand-lg .dropdown-menu:hover {
    display: block;
}

#nav-control .hamburger-line {
    background: white;
    height: 2px;
    width: 100%;
    position:absolute;
    transition: all 0.2s;

    &:nth-child(1) {
        top: 0%;
    }

    &:nth-child(2),
    &:nth-child(4) {
        top: 50%;
        transform: translateY(-50%);
    }

    &:nth-child(3) {
        bottom: 0%;
    }
}

#nav-control {
    width: 1.5rem;
    height: 1.25rem;
    margin: 0.5rem 0;
    position: relative;
}

.navbar-toggler[aria-expanded="true"] #nav-control .hamburger-line {
    &:nth-child(1),
    &:nth-child(3) {
        opacity: 0;
    }

    &:nth-child(2) {
        transform: translateY(-50%) rotate(45deg);
    }
    &:nth-child(4) {
        transform: translateY(-50%) rotate(-45deg);
    }
}

.navbar-toggler:focus {
    box-shadow: 0 0 0 1px;
}


.dropdown-menu[data-bs-popper] {
    margin-top: 0;
}

.dropdown-menu {
    z-index: 1021;

    & .dropdown-item:active {
        background-color: var(--gray-light);
        color: var(--black);
    }
}

.volume-alert, 
.alert {
    color: var(--white);
    background-color: var(--brand-primary);
    border: none;
    border-radius: 0;

    & .alert-info {
        margin: 0;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        padding-left: 0;
        line-height: 1.5rem;
    }

    & a {
        padding: 0 0.5rem;
        color: var(--white);
        &:hover,
        &:focus {
            color: var(--gray-light);
        }
    }

    & .btn-close {
        padding: 0.75rem;
        margin-right: -0.75rem;
    }
}