/* form controls */
input:not([type="radio"]):not([type="checkbox"]), textarea, select.form-control {
    border-radius: 0;
}

.form-control, 
.input-group-prepend, 
.input-group-append, 
.input-group-text, 
.form-select,
.form-check-input {
    border: 1px solid var(--gray);
    border-radius: 0px;
}

.form-control:focus,
.form-select:focus,
.form-check-input:focus {
    border-color: var(--black);
    box-shadow: none;
}

.form-check-input:checked {
    background-color: var(--black);
    border-color: var(--black);
}

.btn:focus {
    box-shadow: none !important;
}

.form-control:not(textarea):not(.form-control-sm), .form-control::file-selector-button {
    height: 53px;
}
.form-control::file-selector-button {
    background-color: #000;
    color: #fff;
}
.form-switch .form-check-input:checked {
    background-color: var(--brand-primary);
    border-color: var(--brand-primary);
}
.form-check-input {
    margin-top: 0.125em;
}

.form-floating > .form-control::placeholder,
.form-floating > .form-control.parsley-error::placeholder,
.form-floating > .form-control.parsley-error:focus::placeholder {
    color: transparent;
}

.has-error,
.parsley-error {
    color: var(--brand-error);

    .text-help,
    label,
    .form-control-label,
    .radio,
    .checkbox,
    .radio-inline,
    .checkbox-inline,
    &.radio label,
    &.checkbox label,
    &.radio-inline label,
    &.checkbox-inline label {
        color: var(--brand-error);
    }

    .r-indicator,
    .c-indicator {
        border-color: var(--brand-error);
    }

    /* Set the border and box shadow on specific inputs to match */

    &.form-control,
    .form-control {
        border-color: var(--brand-error);
        color: var(--brand-error);

        /* Placeholder */

        &::placeholder {
            color: var(--brand-error);
        }

        &:focus,
        &:active {
            border-color: var(--brand-error);

            &::placeholder {
            color: var(--brand-error);
            }
        }
    }
}
.show-password-container {
    .hide-password-icon,
    .show-password-icon {

    }

    input ~ button,
    input ~ button::after {
        transition: border-color .15s ease-in-out, margin .15s ease-in-out;
    }

    input ~ button::after {
        content: '';
        content:'';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
    }

    input.parsley-error:active,
    input.parsley-error:focus {
        box-shadow: none;
    }

    input.parsley-error ~ button {
        border-color: var(--brand-primary);
    }
}

.input-group .btn {
    border-radius: 0;

    &.btn-outline-primary {
        border-color: var(--gray);
        color: var(--black);

        &:hover,
        &:active {
            border-color: var(--black);
            background-color: var(--black);
            color: var(--white);
        }
        &:focus {
            border-color: var(--black);
            box-shadow: none;
        }
    }

    &.btn-subtract + input{
        border-left: none;
        border-right: none;
        background-color: var(--gray-light);
    }

    &.btn-subtract {
        border-right: none;
    }

    &.btn-add {
        border-left: none;
    }
}

.input-group-text {
    background-color: var(--white);
}

input[type=file]::file-selector-button {
    text-transform: uppercase;
}
