.footer-social li:first-child a {
    padding-left: 0px;
}

.footer-links h6.nav-title {
    font-size: 0.75rem;
}
.footer-links li {
    font-size: 0.75rem;
}
.footer-links a {
    text-decoration: none;
    color: #757575;
}
.footer-links a:hover, p.text-copyright a {
    text-decoration: underline;
}

.footer-links-mobile ul {
    list-style: none;
    padding-left: 0;
}
.footer-links-mobile li {
    padding: 15px 15px;
    border-top: 1px solid #757575;
}

.footer-banner {
    background-color: var(--gray-light);

    & .nav-link {
        color: var(--black);
    }
}

.footer-teal {
    background-color: var(--brand-secondary);
}

.footer-teal a {
    color: #fff;
    text-decoration: underline;
}

    .footer-teal a:hover {
        color: #fff;
    }

.footer-black {
    background-color: #000;
}

.text-copyright {
    font-size: .75rem;
}