.carousel-control-next, 
.carousel-control-prev {
    width: 5%;
}

.hero-text {
    padding: 2.5rem 0;
}

.hero-text,
.hero-text h1,
.hero-text h2 {
    line-height: 0.77;
    letter-spacing: -0.06em;
}

.hero-text p,
.hero-text li {
    line-height: initial;
    letter-spacing: initial;
}

.hero-text a {
    text-decoration: none;
}

.hero-text .text-white {
    @media(--md-viewport-down) {
        color: var(--black) !important;
    }
}

.hero-top-left {
    position: absolute;
    top: 0;
    /*left: 5%;*/
}

.hero-top-center {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0%);
    width: 100%;
}

.hero-top-right {
    position: absolute;
    top: 0;
    /*right: 5%;*/
}

.hero-middle-left {
    position: absolute;
    top: 50%;
    /*left: 5%;*/
    transform: translate(0%, -50%);
}

.hero-middle-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}

.hero-middle-right {
    position: absolute;
    top: 50%;
    /*right: 5%;*/
    transform: translate(0%, -50%);
}

.hero-bottom-left {
    position: absolute;
    bottom: 0;
    /*left: 5%;*/
}

.hero-bottom-center {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0%);
    width: 100%;
}

.hero-bottom-right {
    position: absolute;
    bottom: 0;
    /*right: 5%;*/
}

.main-hero,
#heroSlider .carousel-item,
#heroSlider .carousel-inner {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;

    @media (--lg-viewport-up) {
        max-height: 22rem;
    }
}

.main-hero img,
#heroSlider img {
    object-fit: cover;
    object-position: center;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}


@media (--md-viewport-down) {
    #heroSlider .hero-text,
    .main-hero .hero-text{
        position: static;
        display: block;
        transform: none;
        padding: 2.5rem 0rem 2.5rem 0rem;
    }

    .main-hero img,
    #heroSlider img {
        width: 100%;
        height: auto;
        object-fit: initial;
    }

    .login-form .main-hero img,
    .main-hero.secondary-hero-banner img {
        object-fit: cover;
        height: 100%;
    }
}

.login-hero {
    min-height: 35rem;
}

.login-form .main-hero {
    @media(--lg-viewport-up) {
        max-height: none;
    }
}