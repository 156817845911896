@import "../../ga/styles/partials/variables";

@import "../../ga/styles/partials/mixins";
@import "../../ga/styles/partials/fontassets";
@import "../../ga/styles/partials/fontsloaded";
@import "../../ga/styles/utilities/colors";
@import "../../ga/styles/partials/accessibility";

@import "../../ga/styles/partials/nav";
@import "../../ga/styles/partials/navbar";
@import "../../ga/styles/partials/footers";
@import "../../ga/styles/partials/password";

@import "partials/nav";
@import "partials/hero";
@import "partials/footer";
@import "partials/forms";
@import "partials/loggedout";
/*@import "partials/cardselector";*/

:root {
    --navbar-nav-font-size: 0.75rem;
}


body {
    background: var(--white);
    color: var(--black);
}

.footer-teal {
    background-color: var(--brand-secondary);
}

.footer-black {
    background-color: #000;
}

.btn {
    border: 1px solid transparent;
}

a.btn:hover {
    text-decoration: none;
}

.btn-spg {
    padding: 15px 25px;
    text-decoration: none;
    text-align: center;
    font-style: normal;
    border-radius: 0;
}

.btn-teal {
    background-color: var(--brand-secondary);
    color: #fff;
    &:hover

{
    background-color: var(--brand-secondary);
    color: #000;
}

}

.btn-black {
    background-color: #000;
    color: #fff;
    &:hover

{
    background-color: #474747;
    color: silver;
}

}

.spg-form input, .spg-form button, .spg-form input[type='checkbox'], .spg-form .input-group-text, .spg-form select {
    border-radius: 0;
}

a {
    text-decoration: none;
    color: var(--brand-secondary);
}

a:hover {
    text-decoration: underline;
    color: var(--brand-secondary);
}

a.nav-link {
    color: #000;
    line-height: calc(74px - 1rem);
    margin-left: 2rem;
}

a.nav-link:hover {
    color: #333;
    text-decoration: none;
}

.navbar-dark.bg-dark .navbar-nav .nav-link:hover,
.navbar-dark.bg-dark .navbar-nav .nav-link:focus {
    color: #f1f1f1;
}

.dashboard-group .bg-dark {
    background-color: #303030 !important;
    text-transform: uppercase;
}

.bg-dark-gray {
    background-color: #474747;
}

.password-requirements {
    font-size: .8rem;
}

.main-hero {
    max-height: 22rem;
    position: relative;
}

    .main-hero img {
        object-fit: cover;
        height: 100%;
    }

.hero-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.25);
}

#welcomeMessage {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: SimonCircularPro-Bold;
    color: #fff;
    font-size: 3rem;
    width: 100%;
    text-align: center;
}

.dashboard-icons {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 1.5rem;
}

.dashboard-icons a:hover,
.dashboard-icons a:focus {
    color: var(--brand-secondary);
}

.dashboard-icon {
    height: 85px;
    width: 85px;
    padding: 10px;
    background-color: #f1f1f1;
    box-shadow: 3px 3px 3px #c1c1c1;
    margin-bottom: 1rem;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.dashboard-icons a {
    color: #000;
}

    .dashboard-icons a:hover {
        text-decoration: none;
    }

@media (max-width: 992px) {
    .dashboard-icons {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}

@media (max-width: 767px) {
    .dashboard-icons {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media (max-width: 545px) {
    .dashboard-icons {
        grid-template-columns: 1fr 1fr;
    }
}

.text-white a {
    color: #fff;
}

    .text-white a:hover,
    .text-white a:focus {
        color: #fff;
        text-decoration: underline;
    }

.logo-loader,
.simon-logo {
    height: 150px;
    width: 275px;
    margin: 0 auto;
}

.logo-loader {
    height: 320px;
}

.simon-logo-path {
    stroke: #000;
    stroke-width: 10px;
    fill: none;
    transform-origin: center;
}

    .simon-logo-path.animatable {
        stroke-dasharray: 509.33;
        stroke-dashoffset: 509.33;
        animation: logoAnimation 1.75s ease-in-out infinite;
    }

.line {
    width: 10px;
    height: 20px;
    background-color: #fff;
    transform: rotate(53.5deg);
    position: relative;
}

    .line.right {
        left: 149px;
        top: 79px;
    }

    .line.left {
        left: 112px;
        top: 73px;
    }

.center {
    text-align: center;
}


@keyframes logoAnimation {
    0% {
        transform: scale(1);
    }

    75% {
        transform: scale(1.05);
    }

    87.5% {
        opacity: 1;
    }

    100% {
        transform: scale(1);
        opacity: 0;
    }

    to {
        stroke-dashoffset: 0;
    }
}

.header-container {
    display: flex;
    justify-content: space-between;
    font-family: SimonCircularPro-Medium;
    font-size: 16px;
}

    .header-container > div {
        flex-shrink: 0;
    }

.hamburger-box {
    display: inline-block;
    height: 22px;
    position: relative;
    width: 23px
}

.hamburger-inner {
    display: block;
    margin-top: -2px;
    top: 50%
}

    .hamburger-inner, .hamburger-inner:after, .hamburger-inner:before {
        background-color: #000;
        border-radius: 0;
        height: 2px;
        position: absolute;
        transition-duration: .15s;
        transition-property: transform;
        transition-timing-function: ease;
        width: 23px
    }

        .hamburger-inner:after, .hamburger-inner:before {
            content: "";
            display: block
        }

        .hamburger-inner:before {
            top: -10px
        }

        .hamburger-inner:after {
            bottom: -10px
        }

.hamburger--squeeze .hamburger-inner {
    transition-duration: .1s;
    transition-timing-function: cubic-bezier(.55,.055,.675,.19)
}

.navbar-toggler .hamburger-inner:before {
    transition: top .1s ease .14s,opacity .1s ease
}

.navbar-toggler .hamburger-inner:after {
    transition: bottom .1s ease .14s,transform .1s cubic-bezier(.55,.055,.675,.19)
}

.navbar-toggler:not(.collapsed) .hamburger-inner {
    transform: rotate(45deg);
    transition-delay: .14s;
    transition-timing-function: cubic-bezier(.215,.61,.355,1);
    width: 26px
}

    .navbar-toggler:not(.collapsed) .hamburger-inner:before {
        opacity: 0;
        top: 0;
        transition: top .1s ease,opacity .1s ease .14s
    }

    .navbar-toggler:not(.collapsed) .hamburger-inner:after {
        bottom: 0;
        transform: rotate(-90deg);
        transition: bottom .1s ease,transform .1s cubic-bezier(.215,.61,.355,1) .14s;
        width: 26px
    }

#mainHeader {
    position: sticky;
    top: 0;
    z-index: 999;
    background-color: #fff;
}

@media (max-width: 992px) {
    #mainHeader {
        background-color: #d9d9d9;
    }

    #navbarMobile {
        z-index: 1000;
        position: fixed;
        width: 100%;
        height: 100%;
        top: 80px;
        right: -100vw;
        display: block;
        opacity: 0;
        transition: opacity 0.2s, right 0.2s !important;
    }

    #navbarMobile > .navbar-nav {
        z-index: 1000;
        background-color: #fff;
        height: calc(100vh - 80px);
        width: 80%;
        padding: 3rem;
    }

    #navbarMobile.show {
        opacity: 1;
        right: 0;
    }

    #navbarMobile .overlay {
        background: rgba(0,0,0,0.25);
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        height: 100vh;
        z-index: -1;
    }

    #navbarMobile.collapse:not(.show) .overlay {
        display: none !important;
    }

    .navbar-nav .nav-item + .nav-item {
        margin-left: 0;
    }
}

.navbar-toggler:active,
.navbar-toggler:hover,
.navbar-toggler:focus {
    box-shadow: none;
}

.navbar-toggler {
    cursor: pointer;
    border: none;
}

:focus:not(.focus-visible) {
    outline: 0;
    box-shadow: none;
}